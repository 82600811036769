<template>
    <CTabs tabs class="nav-underline nav-underline-primary">
         <CTab active>
           <template slot="title">
             <CIcon name="cil-task" />&nbsp;Filtre
           </template>
           <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
              <CCol col="6">
                <CInput
                  label="ID"
                  placeholder="Hareket ID"
                  horizontal
                  type="number"
                  v-on:keyup.enter="filter"
                  v-model="data.id"
                />
              </CCol>
              <CCol col="3" >
                <CButton type="submit" size="sm" color="primary" @click="filter">
                  <CIcon name="cil-check-circle"/>
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" >
                <CButton type="reset" size="sm" color="danger" @click="clearFilters">
                  <CIcon name="cil-ban"/>
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CInput
                  label="Hareket Zamanı"
                  horizontal
                  style="width: 50%"
                  type="date"
                  v-model="data.startTime"
                />
                <CInput
                  label=" "
                  horizontal
                  style="width: 50%"
                  type="date"
                  v-model="data.endTime"
                />
              </CCol>
            </CRow>
            <hr/>
            <CRow>
              <CCol sm="6">
                <CSelect
                  label="Alınan Stok Kanalı"
                  horizontal
                  placeholder="Seçiniz.."
                  :options="stockChannelNames"
                  :value.sync="data.posFrom_id"
                />
              </CCol>
              <CCol sm="6">
                <CSelect
                  label="Verilen Stok Kanalı"
                  horizontal
                  placeholder="Seçiniz.."
                  :options="stockChannelNames"
                  :value.sync="data.posTo_id"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Bulunan"
                  placeholder="0"
                  horizontal
                  type="number"
                  v-on:keyup.enter="filter"
                  v-model="data.numFound"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Taşınan"
                  placeholder="0"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.numMoved"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Bulunan Toplam"
                  placeholder="0"
                  horizontal
                  type="number"
                  v-on:keyup.enter="filter"
                  v-model="data.totalAmountFound"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Taşınan Toplam"
                  placeholder="0"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.totalAmountMoved"
                />
              </CCol>
            </CRow>
           </CForm>
         </CTab>
     </CTabs>
 </template>
 
 <script>
    export default{
        name: "MoveLogFilter",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
            filter: Function
        },
        computed:{
            stockChannelNames: function() {
               let data = []
               this.$store.getters.activePos.map(r => data.push({value:r.id, label: r.name}))
               return data
           }
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
        methods:{
             clearFilters: function(){
                 this.data = {}
                 this.filter()
             }
        }
    }
 </script>